import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { fetchTodos, addTodo, deleteTodo } from '../../services/api';

const TodoList = () => {
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState({ task: '' });

  useEffect(() => {
    const getTodos = async () => {
      try {
        const response = await fetchTodos();
        setTodos(response.data);
      } catch (error) {
        console.error('Error fetching todos', error);
      }
    };
    getTodos();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTodo({ ...newTodo, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addTodo(newTodo);
      setTodos(prevTodos => [...prevTodos, response.data]);
      setNewTodo({ task: '' });
    } catch (error) {
      console.error('Error adding todo', error);
    }
  };

  const handleDelete = async (todoId) => {
    try {
      await deleteTodo(todoId);
      setTodos(todos.filter(todo => todo.id !== todoId));
    } catch (error) {
      console.error('Error deleting todo', error);
    }
  };

  return (
    <Container>
      <h2 className="my-4">Todo List</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={8}>
            <Form.Control
              type="text"
              name="task"
              value={newTodo.task}
              onChange={handleInputChange}
              placeholder="Enter a new task"
              required
            />
          </Col>
          <Col sm={4}>
            <Button type="submit" variant="primary">Add Task</Button>
          </Col>
        </Row>
      </Form>

      <Table striped bordered hover className="mt-4">
        <thead>
          <tr>
            <th>Task</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {todos.map(todo => (
            <tr key={todo.id}>
              <td>{todo.task}</td>
              <td>
                <Button
                  variant="outline-danger"
                  onClick={() => handleDelete(todo.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default TodoList;