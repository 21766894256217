import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';
import { fetchDrivers, addDriver, deleteDriver, updateDriver } from '../../services/api';

const DriverList = () => {
  const [drivers, setDrivers] = useState([]);
  const [newDriver, setNewDriver] = useState({ name: '', wins: '', titles: '', title_years: '' });
  const [deleteConfirmation, setDeleteConfirmation] = useState({ show: false, driverId: null, driverName: '' });
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const [editingDriver, setEditingDriver] = useState(null);
  const [sortDirection, setSortDirection] = useState('desc');

  useEffect(() => {
    const getDrivers = async () => {
      try {
        const response = await fetchDrivers();
        setDrivers(response.data);
      } catch (error) {
        console.error('Error fetching drivers', error);
      }
    };
    getDrivers();
  }, []);

  const sortedDrivers = useMemo(() => {
    return [...drivers].sort((a, b) =>
      sortDirection === 'asc' ? a.wins - b.wins : b.wins - a.wins
    );
  }, [drivers, sortDirection]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDriver({ ...newDriver, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addDriver(newDriver);
      setDrivers(prevDrivers => [...prevDrivers, response.data]);
      setNewDriver({ name: '', wins: '', titles: '', title_years: '' });
    } catch (error) {
      console.error('Error adding driver', error);
    }
  };

  const handleDeleteConfirmation = (driverId, driverName) => {
    setDeleteConfirmation({ show: true, driverId, driverName });
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmation({ show: false, driverId: null, driverName: '' });
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteDriver(deleteConfirmation.driverId);
      setDrivers(drivers.filter(driver => driver.id !== deleteConfirmation.driverId));
      setDeleteConfirmation({ show: false, driverId: null, driverName: '' });
    } catch (error) {
      console.error('Error deleting driver', error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedDrivers(prevSelectedDrivers =>
      prevSelectedDrivers.includes(id)
        ? prevSelectedDrivers.filter(driverId => driverId !== id)
        : [...prevSelectedDrivers, id]
    );
  };

  const handleShowOnlySelectedChange = () => {
    setShowOnlySelected(!showOnlySelected);
  };

  const startEditing = (driver) => {
    setEditingDriver(driver);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingDriver({ ...editingDriver, [name]: value });
  };

  const cancelEdit = () => {
    setEditingDriver(null);
  };

  const saveEdit = async () => {
    try {
      const response = await updateDriver(editingDriver.id, editingDriver);
      setDrivers(prevDrivers => prevDrivers.map(driver => (driver.id === editingDriver.id ? response.data : driver)));
      setEditingDriver(null);
    } catch (error) {
      console.error('Error updating driver', error);
    }
  };

  const toggleSortDirection = () => {
    setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
  };

  const displayedDrivers = useMemo(() => {
    return showOnlySelected
      ? sortedDrivers.filter(driver => selectedDrivers.includes(driver.id))
      : sortedDrivers;
  }, [showOnlySelected, sortedDrivers, selectedDrivers]);

  return (
    <Container>
      <h2 className="my-4">NASCAR Cup Driver Stats</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={2}>
            <Form.Control
              type="text"
              name="name"
              value={newDriver.name}
              onChange={handleInputChange}
              placeholder="Name"
              required
            />
          </Col>
          <Col sm={2}>
            <Form.Control
              type="number"
              name="wins"
              value={newDriver.wins}
              onChange={handleInputChange}
              placeholder="Wins"
              required
            />
          </Col>
          <Col sm={2}>
            <Form.Control
              type="number"
              name="titles"
              value={newDriver.titles}
              onChange={handleInputChange}
              placeholder="Titles"
            />
          </Col>
          <Col sm={3}>
            <Form.Control
              type="text"
              name="title_years"
              value={newDriver.title_years}
              onChange={handleInputChange}
              placeholder="Title Years"
            />
          </Col>
          <Col sm={3}>
            <Button type="submit" variant="primary">Add Driver</Button>
          </Col>
        </Row>
      </Form>

      <Form.Check
        type="checkbox"
        label="Show only selected drivers"
        checked={showOnlySelected}
        onChange={handleShowOnlySelectedChange}
        className="my-3"
      />

      <Table striped bordered hover>
        <thead>
          <tr>
            <th width="10%">Select</th>
            <th>Name</th>
            <th onClick={toggleSortDirection} style={{ cursor: 'pointer' }}>
              Wins {sortDirection === 'asc' ? '▲' : '▼'}
            </th>
            <th>Titles</th>
            <th>Title Years</th>
            <th width="20%">Actions</th>
          </tr>
        </thead>
        <tbody>
          {displayedDrivers.map(driver => (
            <tr key={driver.id}>
              <td>
                <Form.Check
                  className="d-flex justify-content-center"
                  type="checkbox"
                  checked={selectedDrivers.includes(driver.id)}
                  onChange={() => handleCheckboxChange(driver.id)}
                />
              </td>
              {editingDriver && editingDriver.id === driver.id ? (
                <>
                  <td>
                    <Form.Control
                      type="text"
                      name="name"
                      value={editingDriver.name}
                      onChange={handleEditInputChange}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      name="wins"
                      value={editingDriver.wins}
                      onChange={handleEditInputChange}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      name="titles"
                      value={editingDriver.titles}
                      onChange={handleEditInputChange}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      name="title_years"
                      value={editingDriver.title_years}
                      onChange={handleEditInputChange}
                    />
                  </td>
                  <td>
                    <Button variant="success" onClick={saveEdit} className="me-2">Save</Button>
                    <Button variant="secondary" onClick={cancelEdit}>Cancel</Button>
                  </td>
                </>
              ) : (
                <>
                  <td>{driver.name}</td>
                  <td>{driver.wins}</td>
                  <td>{driver.titles}</td>
                  <td>{driver.title_years}</td>
                  <td className="d-flex justify-content-center">
                    <Button
                      variant="outline-primary"
                      onClick={() => startEditing(driver)}
                      className="me-2"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outline-danger"
                      onClick={() => handleDeleteConfirmation(driver.id, driver.name)}
                    >
                      Delete
                    </Button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={deleteConfirmation.show} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {deleteConfirmation.driverName}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DriverList;
