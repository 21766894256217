import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import Home from './components/Home/Home';
import TodoList from './components/TodoList/TodoList';
import DriverList from './components/DriverList/DriverList';
import PlatformToolbar from '@apex/react-toolkit/components/PlatformToolbar';
import { fetchCanSee } from './services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import './App.css';

const App = () => {
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getAuth = async () => {
      try {
        const response = await fetchCanSee();
        setIsAuthorized(response.data.canSee);
      } catch (error) {
        console.error('Error fetching auth', error);
        setIsAuthorized(false);
      } finally {
        setIsLoading(false);
      }
    };

    getAuth();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isAuthorized === false) {
    return (
      <div>
        <h1>401 Unauthorized</h1>
        <p>You do not have permission to view this page.</p>
      </div>
    );
  }

  return (
    <Router>
      <PlatformToolbar
        navigationItems={[
          <Nav.Link href="/">Home</Nav.Link>,
          <Nav.Link href="/todos">Todo List</Nav.Link>,
          <Nav.Link href="/drivers">Driver Table</Nav.Link>,
        ]}
        icon={<FontAwesomeIcon icon={faList} className="mb-0" style={{ height: '20px' }} />}
        name="React Demo"
        isTesting={true}
      />
      <div className="app-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/todos" element={<TodoList />} />
          <Route path="/drivers" element={<DriverList />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
