import axios from 'axios';

const { origin } = window.location;

const getBaseURL = () => {
  if (origin.includes('local')) {
    return 'http://localhost:5000/api';
  } else {
    return `${origin}/api`;
  }
};

const api = axios.create({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
  'withCredentials': origin.includes('local'),
});

export const fetchHealthcheck = () => api.get('/healthcheck');
export const fetchCanSee = () => api.get('/cansee');

export const fetchTodos = () => api.get(`/todos`);
export const addTodo = (todo) => api.post(`/todos`, todo);
export const deleteTodo = (id) => api.delete(`/todos/${id}`);

export const fetchDrivers = () => api.get('/drivers');
export const addDriver = (driver) => api.post('/drivers', driver);
export const updateDriver = (id, driver) => api.put(`/drivers/${id}`, driver);
export const deleteDriver = (id) => api.delete(`/drivers/${id}`);

export default api;
