import React from 'react';
import './Home.css';

const Home = () => (
  <div id="home-page">
    <h1 className="center-align">React Demo</h1>
    <div className="container-padding">
      <h5 className="paragraph-padding-center">
        A React application connected to a PostgreSQL database offers users a powerful and efficient solution for building dynamic, data-driven web applications. By integrating with a PostgreSQL database, the application can store, retrieve, and manipulate structured data efficiently. From data visualization dashboards to content management systems, React and PostgreSQL together offer a robust foundation for building scalable and data-intensive applications.
      </h5>
      <h3 className="center-align">Related documentation</h3>
      <div className="bordered-box-container">
        <div className="bordered-box">
          <div className="inline-element-margin align-left">
            <i className="fas fa-code-branch"></i>
            <span className="span-margin-left"> Repository: </span>
            <a href="https://git.ops.trddev.io/apex/starter-apps/javascript/react-sample-app" target="_blank" rel="noopener noreferrer">
              https://git.ops.trddev.io/apex/starter-apps/javascript/react-sample-app
            </a>
          </div>
          <div className="inline-element-margin align-left">
            <i className="fas fa-book"></i>
            <span className="span-margin-left"> Apex Documentation: </span>
            <a href="https://documentation.app.apex-mp.com/guides/plotly-dash/plotly-on-apex" target="_blank" rel="noopener noreferrer">
              https://documentation.app.apex-mp.com/guides/plotly-dash/plotly-on-apex
            </a>
          </div>
          <div className="align-left">
            <i className="fas fa-square-poll-vertical"></i>
            <span className="span-margin-left"> React docs: </span>
            <a href="https://react.dev/" target="_blank" rel="noopener noreferrer">
              https://react.dev/
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Home;
